import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import FormRender from "../../setting/form-setting/FormRender/FormRender.vue";
import UserPicker from "../../components/UserPicker/UserPicker.vue";
import PickerItems from "../../components/PickerItems/PickerItems.vue";
import { Phone } from "@element-plus/icons-vue";
import API from "@/api/flowable/workspace/work";
import axios from "axios";
import ProjectAPI from "@/api/project/project-list";

@Options({
    name: "flowable-workspace-process-workspace",
    components: {
        FormRender,
        UserPicker,
        PickerItems,
    },
    emits: ["flowSubmit"],
})
export default class ProcessWorkspace extends BaseFunction {
    private assigneeDialogVisible = false;
    private assigneeDialogType = "";
    private showProcess = false;
    private item = this.defaultItem();
    private isLoading = false;
    private isLoading1 = false;
    private processInstanceId: any = "";
    private taskId: any = "";
    private processInfo: any = {};
    private isProcess = false;
    private workCode = "";
    private defaultUrl: any = "https://img9.sotecauto.com/";
    public mediaList: Array<string> = [];
    public imageList: Array<string> = [];
    public previewSrcList: Array<string> = [];
    private currentPageOptions: any = {};

    get Phone() {
        return Phone;
    }

    public defaultItem(): any {
        return {
            design: {},
            endList: {},
            runningList: {},
            noTakeList: {},
            detailVOList: {},
            startUser: {},
            formData: {},
            currentNode: {},
            totalDuration: "",
            nodeDuration: "",
        };
    }

    public show(item: any) {
        this.currentPageOptions = item;
        this.workCode = this.currentPageOptions.workCode || "";
        this.taskId = this.currentPageOptions.taskId || "";
        this.processInstanceId = this.currentPageOptions.processInstanceId || "";
        this.isProcess = !this.$tools.isEmpty(this.currentPageOptions.isProcess) && this.currentPageOptions.isProcess === true;
        this.processInfo = {
            workCode: this.workCode,
            taskId: this.taskId,
            processInstanceId: this.processInstanceId,
        };
        this.showProcess = true;
        this.getProcessInfo();
    }

    //获取媒体路径
    public mediaFullUrl(path: string): string {
        return this.defaultUrl + path;
    }
    //获取工单信息
    public getProcessInfo() {
        this.item = this.defaultItem();
        this.isLoading = true;
        API.getProcessInstanceInfo(this.processInfo)
            .then((rsp: any) => {
                // console.log("流程处理1：", rsp.data);

                const processInfo = rsp.data;

                //解析form表单
                const formItems = this.$tools.flatFormItem(processInfo.formItems);
                const readerFormItems: any = [];
                const editFormItems: any = [];

                const perms = processInfo?.currentNode?.props?.formPerms || [];
                const map = new Map(perms.map((it: any) => [it.id, it.perm]));
                const removeIndices = [];
                for (let i = 0; i < formItems.length; i++) {
                    const formItem = formItems[i];
                    const readerFormItem = JSON.parse(JSON.stringify(formItem));
                    const editFormItem = JSON.parse(JSON.stringify(formItem));
                    const perm = map.get(formItem.id);
                    if (perm === "E") {
                        formItem.props.readerMode = true;
                        readerFormItem.props.readerMode = true;
                        readerFormItems.push(readerFormItem);
                        editFormItem.props.readerMode = false;
                        editFormItems.push(editFormItem);
                    } else if (perm === "R") {
                        formItem.props.readerMode = true;
                        readerFormItem.props.readerMode = true;
                        readerFormItems.push(readerFormItem);
                    } else if (perm === "H") {
                        removeIndices.push(i);
                    } else {
                        formItem.props.readerMode = true;
                        readerFormItem.props.readerMode = true;
                        readerFormItems.push(readerFormItem);
                    }
                }
                removeIndices.reverse().forEach((it) => formItems.splice(it, 1));
                processInfo.formItems = formItems.reverse();
                processInfo.readerFormItems = readerFormItems.reverse();
                processInfo.editFormItems = editFormItems.reverse();

                //解析审批记录
                let labelTime = "";
                const detailVOList: any = [];
                for (let i = 0; i < processInfo.detailVOList.length; i++) {
                    const detailItem = processInfo.detailVOList[i];
                    const date = this.$tools.formatSimpleDate(detailItem.createTime);
                    if (date != labelTime) {
                        labelTime = date;
                        detailVOList.push({ activityType: "time", createTime: detailItem.createTime });
                    }
                    detailVOList.push(detailItem);

                    //生成预览列表
                    if (["image"].includes(detailItem.commentType)) {
                        const comments = this.getJSON(detailItem.commentVO.comments);
                        for (const i in comments) {
                            const key = comments[i].key;
                            if (!["image"].includes(key)) this.mediaList.push(this.mediaFullUrl(key));
                        }
                    }
                }

                this.item = {
                    endList: processInfo.endList,
                    runningList: processInfo.runningList,
                    noTakeList: processInfo.noTakeList,
                    startUser: processInfo.startUser,
                    formData: processInfo.formData,
                    currentNode: processInfo.currentNode,
                    processStatus: processInfo.processStatus,
                    workInfo: processInfo.workInfo && processInfo.workInfo.length > 0 ? processInfo.workInfo[0] : {},
                    totalDuration: processInfo.totalDuration,
                    nodeDuration: processInfo.nodeDuration,

                    formItems: processInfo.formItems,
                    readerFormItems: processInfo.readerFormItems,
                    editFormItems: processInfo.editFormItems,
                    processDefinitionId: processInfo.processDefinitionId,
                    processDefinitionName: processInfo.processDefinitionName,
                    processDefinitionVersion: processInfo.processDefinitionVersion,

                    detailVOList: detailVOList,
                };
                //console.log("流程处理2：", this.item);
                this.remarkDialogForm.workDescribeLaunch = this.workInfo.workDescribeLaunch; //填充故障描述
                for (let i = 0; i < this.item.detailVOList.length; i++) {
                    if (this.item.detailVOList[i].commentType == "image") {
                        const comments = this.getJSON(this.item.detailVOList[i].commentVO.comments);
                        // 向 imageList 数组中添加数据
                        this.imageList.push(...comments);
                    }
                }
                this.previewSrcList = this.imageList.map((item: any) => {
                    return this.defaultUrl + item.key;
                });
                //console.log(this.previewSrcList);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    get endList() {
        return this.item.endList;
    }
    get runningList() {
        return this.item.runningList;
    }
    get noTakeList() {
        return this.item.noTakeList;
    }
    get detailVOList() {
        return this.item.detailVOList;
    }
    get startUser() {
        return this.item.startUser;
    }
    get formData() {
        return this.item.formData;
    }
    get currentNode() {
        return this.item.currentNode;
    }
    get totalDuration() {
        return this.item.totalDuration;
    }
    get nodeDuration() {
        return this.item.nodeDuration;
    }
    get formItems() {
        return this.item.formItems;
    }
    get readerFormItems() {
        return this.item.readerFormItems;
    }
    get editFormItems() {
        return this.item.editFormItems;
    }
    get processDefinitionId() {
        return this.item.processDefinitionId;
    }
    get processDefinitionName() {
        return this.item.processDefinitionName;
    }
    get processDefinitionVersion() {
        return this.item.processDefinitionVersion;
    }
    get startUserIcon() {
        return this.startUser.icon || "https://img9.sotecauto.com/avatar/sotec.png";
    }
    get startUserName() {
        return this.startUser.title || "";
    }
    get startUserPhone() {
        return this.startUser.phone || "未知";
    }
    get workInfo() {
        return this.item.workInfo || {};
    }

    //是否进行中
    get isRunning() {
        return this.item.processStatus === "1";
    }
    //是否是发起人
    get isStartUser() {
        return this.authUser.userCode === parseInt(this.startUser.value);
    }
    //是否是审批人
    get isAssignee() {
        if (!this.isProcess) return false;
        if (!this.isRunning) return false;
        if (!this.processInfo) return false;
        if (!this.taskId) return false;
        if (this.detailVOList.length < 1) return false;
        for (let i = 0; i < this.detailVOList.length; i++) {
            const task = this.detailVOList[i];
            if (!this.$tools.isEmpty(task.taskId) && !this.$tools.isEmpty(task.assigneeList) && this.$tools.isEmpty(task.endTime)) {
                for (let j = 0; j < task.assigneeList.length; j++) {
                    //console.log(task.assigneeList[j]);
                    if (task.assigneeList[j] && this.authUser.userCode === task.assigneeList[j].value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    //是否可以催办
    get isUrging() {
        if (!this.isRunning) return false;
        if (!this.processInfo) return false;
        if (this.isAssignee) return false;
        if (this.isStartUser) return true;
        if (this.detailVOList.length < 1) return false;
        for (let i = 0; i < this.detailVOList.length; i++) {
            const task = this.detailVOList[i];
            if (!this.$tools.isEmpty(task.assigneeList)) {
                for (let j = 0; j < task.assigneeList.length; j++) {
                    //console.log(task.assigneeList[j]);
                    if (task.assigneeList[j] && this.authUser.userCode === task.assigneeList[j].value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    //流程状态
    get processStatus() {
        // let status = "";
        switch (this.item.processStatus) {
            case "0":
                status = "已创建";
                break;
            case "1":
                status = "进行中";
                break;
            case "2":
                status = "已撤销";
                break;
            case "3":
                status = "已驳回";
                break;
            case "4":
                status = "已结束";
                break;
            default:
                status = "...";
                break;
        }
        return status;
    }
    //获取审批人头像
    public getAssigneeAvatar(optionVOList: any) {
        if (optionVOList) {
            return optionVOList.userIcon;
        } else {
            return "https://img9.sotecauto.com/avatar/sotec.png";
        }
    }
    //审批指令
    private assigneeDialogCurrentConfig: any = [];
    private assigneeDialogConfig: any = {
        Agree: { title: "处理", comments: "处理", remark: "处理任务，任务将流转至下一节点" },
        Reject: { title: "驳回", comments: "驳回", remark: "驳回工单，将会结束工单" },
        Revoke: { title: "撤销", comments: "撤销", remark: "撤销工单，将会结束工单" },
        Rollback: { title: "转移", comments: "转移", remark: "转移工单，可选择转移到哪个节点处理" },
        Delegate: { title: "委派", comments: "委派", remark: "委派任务，委办人处理完成后，任务将返回当前节点" },
        Assignee: { title: "转办", comments: "转办", remark: "转办任务，转办人处理完成后，任务将流转至下一节点" },
        Comments: { title: "评论", comments: "评论", remark: "仅发表评论，不做任何处理" },
        Image: { title: "图片", comments: "图片", remark: "仅上传图片，不做任何处理" },
        Video: { title: "视频", comments: "视频", remark: "仅上传视频，不做任何处理" },
        Attachment: { title: "附件", comments: "附件", remark: "仅上传附件，不做任何处理" },
        Urging: { title: "催办", comments: "催办", remark: "发起人和处理过的人可以催办" },
    };
    //打开审批窗口
    public assigneeDialogCommand(type: any) {
        this.assigneeDialogType = type;
        this.assigneeDialogCurrentConfig = this.assigneeDialogConfig[type];
        this.assigneeDialogForm.comments = this.assigneeDialogConfig[type].comments;
        if (type === "Image") {
            //图片上传
            this.uploadImage("uploadImage");
            return;
        } else if (type === "Video") {
            //视频上传
            this.uploadVideo("uploadVideo");
            return;
        } else if (type === "Attachment") {
            //附件上传
            this.uploadFile("uploadFile");
            return;
        } else if (type === "Urging") {
            //催办
            this.urgingSubmit();
            return;
        } else {
            this.assigneeDialogVisible = true;
        }
    }
    //审批提交
    private assigneeDialogForm: any = {
        comments: "",
        rollbackId: "",
        assignedUser: null,
        assignedUserName: "",
    };
    //用户选择触发
    public userSelected(select: any) {
        this.assigneeDialogForm.assignedUser.length = 0;
        select.forEach((val: any) => this.assigneeDialogForm.assignedUser.push(val));
    }
    //审批提交的数据
    get approveInfo() {
        const dialogForm = Object.assign({}, this.assigneeDialogForm);
        dialogForm.assignedUser = [
            {
                title: dialogForm.assignedUserName,
                value: dialogForm.assignedUser,
            },
        ];
        delete dialogForm.assignedUserName;
        return {
            ...dialogForm,
            ...this.processInfo,
            formData: this.formData,
        };
    }
    //审批提交
    public assigneeDialogSubmit(form1: any, form2: any) {
        (this.$refs[form1] as any).validate((valid: any) => {
            if (valid) {
                (this.$refs[form2] as any).validate((valid: any) => {
                    if (valid) {
                        this.isLoading1 = true;
                        (API as any)
                            [this.assigneeDialogType.toLowerCase()](this.approveInfo)
                            .then((res: any) => {
                                //console.log(this.assigneeDialogConfig[this.assigneeDialogType].title + "res", res);
                                this.getProcessInfo();
                                this.$emit("flowSubmit", "RefreshTable");
                                this.toast.success(res.msg);
                                this.assigneeDialogVisible = false;
                            })
                            .finally(() => {
                                this.isLoading1 = false;
                            });
                    }
                });
            }
        });
    }

    //审批表单校验
    get assigneeDialogForm1Rule(): any {
        const rule: any = {};
        if (this.editFormItems && this.editFormItems.length > 0) {
            for (let i = 0; i < this.editFormItems.length; i++) {
                const item = this.editFormItems[i];
                rule[item.id] = [{ required: true, message: "不能为空", trigger: "blur" }];
            }
        }
        return rule;
    }
    //审批表单校验
    get assigneeDialogForm2Rule(): any {
        const rule: any = {};
        if (["Delegate", "Assignee"].includes(this.assigneeDialogType)) {
            rule["assignedUser"] = [{ required: true, message: "请选择用户", trigger: "blur" }];
        }
        if (["Rollback"].includes(this.assigneeDialogType)) {
            rule["rollbackId"] = [{ required: true, message: "请选择退回节点", trigger: "blur" }];
        }
        rule["comments"] = [{ required: true, message: "请输入处理意见", trigger: "blur" }];
        return rule;
    }

    // //获取代理委托处理
    // public getAssignList(options: any): any {
    //     const assignList: any = [];
    //     for (let i = 0; i < options.length; i++) {
    //         let name = "未知";
    //         if (options[i] === "YW") {
    //             name = "运维部";
    //         } else if (options[i] === "JC") {
    //             name = "集成部";
    //         } else if (options[i] === "XX") {
    //             name = "信息部";
    //         } else if (options[i] === "WC") {
    //             name = "直接完成";
    //         }
    //         assignList.push({ value: options[i], label: name });
    //     }
    //     return assignList;
    // }

    //获取可退回列表
    public getRollbackList(detailVOList: any): any {
        const rollbackList: any = [];
        for (let i = 0; i < detailVOList.length; i++) {
            const node = detailVOList[i];
            if (node.nodeType === "userTask" && i < detailVOList.length - 1) rollbackList.push(node);
        }
        const newArr = [];
        const obj: any = {};
        for (let i = 0; i < rollbackList.length; i++) {
            const node = rollbackList[i];
            if (!obj[node.activityId]) {
                newArr.push({ value: node.activityId, name: node.activityName });
                obj[node.activityId] = true;
            }
        }
        return newArr;
    }

    //审批意见重复比较
    private optionArray: any = [];
    public isOptionEqual(option: any) {
        for (let i = 0; i < this.optionArray.length; i++) {
            if (this.isEqual(option, this.optionArray[i])) return true;
        }
        this.optionArray.push(Object.assign({}, option));
        return false;
    }
    private curActivityId = "";
    public isEqual(object1: any, object2: any) {
        //ActivityId变更时
        if (this.curActivityId !== object1.activityId) {
            this.curActivityId = object1.activityId;
            this.optionArray.length = 0;
            return false;
        }
        return object1.comments == object2.comments && object1.type == object2.type && object1.userCode == object2.userCode && object1.name == object2.name;
    }

    public getJSON(comments: any) {
        if (!comments) return [];
        try {
            return JSON.parse(comments);
        } catch (e) {
            console.error("Invalid JSON string:", comments);
            return [];
        }
    }
    //故障描述表单
    public remarkDialogForm = {
        workDescribeLaunch: "",
    };

    //上传图片
    private upload(event: string) {
        if (event === "IMAGE") {
            const input = this.$refs.imageInput as HTMLInputElement;
            input.click();
        } else if (event === "VIDEO") {
            const input = this.$refs.videoInput as HTMLInputElement;
            input.click();
        } else if (event === "FILE") {
            const input = this.$refs.fileInput as HTMLInputElement;
            input.click();
        }
    }

    private uploadImage(event: any) {
        this.isLoading = true;
        const files = event.target.files;
        const fileObject: any = {};
        const tempFiles: any = [];
        for (let i = 0; i < files.length; i++) {
            if (!fileObject[files[i].name]) fileObject[files[i].name] = files[i];
            tempFiles.push({
                name: files[i].name,
                size: files[i].size,
                tempFilePath: files[i].name,
            });
        }
        if (this.$tools.isEmpty(tempFiles)) {
            return;
        }
        const item: any = {
            ...this.processInfo,
            infoData: tempFiles,
            infoType: "video",
        };
        API.uploadImage(item)
            .then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    const formData = new FormData();
                    formData.append("file", fileObject[response.data[i].tempFilePath]);
                    formData.append("key", response.data[i].key);
                    formData.append("token", response.data[i].token);
                    axios
                        .post("https://upload-cn-east-2.qiniup.com", formData, { headers: { "Content-Type": "multipart/form-data" } })
                        .then((res: any) => {
                            if (res.status === 200) {
                                console.log(res);
                                this.toast.success("图片上传成功");
                            } else {
                                this.toast.error("图片上传失败");
                            }
                            this.getProcessInfo();
                            this.$emit("flowSubmit", "RefreshTable");
                            this.assigneeDialogVisible = false;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            })
            .catch((error) => {
                this.isLoading = false;
                this.$tools.toast.error(error + "保存失败！", 20000);
            });
    }

    //上传视频
    private uploadVideo(event: any) {
        this.isLoading = true;
        const files = event.target.files;
        const fileObject: any = {};
        const tempFiles: any = [];
        for (let i = 0; i < files.length; i++) {
            if (!fileObject[files[i].name]) fileObject[files[i].name] = files[i];
            tempFiles.push({
                name: files[i].name,
                size: files[i].size,
                tempFilePath: files[i].name,
            });
        }
        if (this.$tools.isEmpty(tempFiles)) {
            return;
        }
        const item: any = {
            ...this.processInfo,
            infoData: tempFiles,
            infoType: "video",
        };
        API.uploadVideo(item)
            .then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    const formData = new FormData();
                    formData.append("file", fileObject[response.data[i].tempFilePath]);
                    formData.append("key", response.data[i].key);
                    formData.append("token", response.data[i].token);
                    axios
                        .post("https://upload-cn-east-2.qiniup.com", formData, { headers: { "Content-Type": "multipart/form-data" } })
                        .then((res: any) => {
                            if (res.status === 200) {
                                this.toast.success("视频上传成功");
                            } else {
                                this.toast.error("视频上传失败");
                            }
                            this.getProcessInfo();
                            this.$emit("flowSubmit", "RefreshTable");
                            this.assigneeDialogVisible = false;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            })
            .catch((error) => {
                this.isLoading = false;
                this.$tools.toast.error(error + "保存失败！", 20000);
            });
    }

    //上传文件
    private uploadFile(event: any) {
        this.isLoading = true;
        const files = event.target.files;
        const fileObject: any = {};
        const tempFiles: any = [];
        for (let i = 0; i < files.length; i++) {
            if (!fileObject[files[i].name]) fileObject[files[i].name] = files[i];
            tempFiles.push({
                name: files[i].name,
                size: files[i].size,
                path: files[i].name,
            });
        }
        // console.log(fileObject);
        if (this.$tools.isEmpty(tempFiles)) {
            return;
        }
        const item: any = {
            ...this.processInfo,
            infoData: tempFiles,
            infoType: "attachment",
            type: "file",
        };
        // console.log(item);
        API.uploadAttachment(item)
            .then((response) => {
                // console.log(response);
                for (let i = 0; i < response.data.length; i++) {
                    const formData = new FormData();
                    formData.append("file", fileObject[response.data[i].tempFilePath]);
                    formData.append("key", response.data[i].key);
                    formData.append("token", response.data[i].token);
                    axios
                        .post("https://upload-cn-east-2.qiniup.com", formData, { headers: { "Content-Type": "multipart/form-data" } })
                        .then((res: any) => {
                            if (res.code === 200) {
                                this.toast.success("文件上传成功");
                            } else {
                                this.toast.error("文件上传失败");
                            }
                            this.getProcessInfo();
                            this.$emit("flowSubmit", "RefreshTable");
                            this.assigneeDialogVisible = false;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            })
            .catch((error) => {
                this.isLoading = false;
                this.$tools.toast.error(error + "保存失败！", 20000);
            });
    }

    //文件下载功能
    public downFile(attachLink: any, fileName: any) {
        const link = document.createElement("a");
        link.href = attachLink;
        link.download = fileName;
        link.click();
    }

    //催办
    public urgingSubmit() {
        this.isLoading = true;
        (API as any)
            .urging(this.processInfo)
            .then((res: any) => {
                this.toast.success(res.msg);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    get projectIdSelectApi() {
        const editFormItem = this.editFormItems.filter((item: any) => item.title === "PROJECTID");
        const cid = editFormItem && editFormItem.length > 0 ? this.formData[editFormItem[0].id] : null;
        return {
            api: (query: any) => ProjectAPI.idSelect(query),
            parent: "org_code",
            status: "project_status",
            name: "project_name",
            id: "project_id",
            cid: cid,
        };
    }
}
